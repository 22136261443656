import "./App.css";

function App() {
  return (
    <>
      {/* Page Container */}
      <div className="w3-content w3-margin-top" style={{ maxWidth: 1400 }}>
        {/* The Grid */}
        <div className="w3-row-padding">
          {/* Left Column */}
          <div className="w3-third">
            <div className="w3-white w3-text-grey w3-card-4">
              <div className="w3-display-container">
                <img src="./logo.bmp" style={{ width: "100%" }} alt="Avatar" />
                <div className="w3-display-bottomleft w3-container w3-text-black">
                  <h2 className="name">Jaysen Wankhade</h2>
                </div>
              </div>
              <div className="w3-container">
                <p>
                  <i className="fa fa-briefcase fa-fw w3-margin-right w3-large w3-text-blue" />
                  Senior Software Engineer
                </p>
                <p>
                  <i className="fa fa-home fa-fw w3-margin-right w3-large w3-text-blue" />
                  Pune, Maharashtra
                </p>
                <p>
                  <i className="fa fa-envelope fa-fw w3-margin-right w3-large w3-text-blue" />
                  jaysenwankhade@hotmail.com
                </p>
                <p>
                  <i className="fa fa-phone fa-fw w3-margin-right w3-large w3-text-blue" />
                  +91 9309505154
                </p>
                <hr />
                <p className="w3-large">
                  <b>
                    <i className="fa fa-asterisk fa-fw w3-margin-right w3-text-blue" />
                    Interests
                  </b>
                </p>
                <p>Full Stack Development</p>
                <div className="w3-light-grey w3-round-xlarge w3-small">
                  <div
                    className="w3-container w3-center w3-round-xlarge w3-blue"
                    style={{ width: "100%" }}
                  >
                    100%
                  </div>
                </div>
                <p>System Design</p>
                <div className="w3-light-grey w3-round-xlarge w3-small">
                  <div
                    className="w3-container w3-center w3-round-xlarge w3-blue"
                    style={{ width: "60%" }}
                  >
                    <div className="w3-center w3-text-white">60%</div>
                  </div>
                </div>
                <p>Cloud Engineering</p>
                <div className="w3-light-grey w3-round-xlarge w3-small">
                  <div
                    className="w3-container w3-center w3-round-xlarge w3-blue"
                    style={{ width: "70%" }}
                  >
                    70%
                  </div>
                </div>
                <p>Frontend Development</p>
                <div className="w3-light-grey w3-round-xlarge w3-small">
                  <div
                    className="w3-container w3-center w3-round-xlarge w3-blue"
                    style={{ width: "80%" }}
                  >
                    80%
                  </div>
                </div>
                <br />
                <p className="w3-large w3-text-theme">
                  <b>
                    <i className="fa fa-globe fa-fw w3-margin-right w3-text-blue" />
                    Languages
                  </b>
                </p>
                <p>English</p>
                <div className="w3-light-grey w3-round-xlarge">
                  <div
                    className="w3-round-xlarge w3-blue"
                    style={{ height: 24, width: "100%" }}
                  />
                </div>
                <p>Hindi</p>
                <div className="w3-light-grey w3-round-xlarge">
                  <div
                    className="w3-round-xlarge w3-blue"
                    style={{ height: 24, width: "100%" }}
                  />
                </div>
                <p>Marathi</p>
                <div className="w3-light-grey w3-round-xlarge">
                  <div
                    className="w3-round-xlarge w3-blue"
                    style={{ height: 24, width: "100%" }}
                  />
                </div>
                <br />
              </div>
            </div>
            <br />
            {/* End Left Column */}
          </div>
          {/* Right Column */}
          <div className="w3-twothird">
            <div className="w3-container w3-card w3-white w3-margin-bottom">
              <h2 className="w3-text-grey w3-padding-16">
                <i className="fa fa-suitcase fa-fw w3-margin-right w3-xxlarge w3-text-blue" />
                Work Experience
              </h2>
              <div className="w3-container">
                <h5 className="w3-opacity">
                  <b>Custom Software Engineering Senior Analyst | Accenture</b>
                </h5>
                <h6 className="w3-text-blue">
                  <i className="fa fa-calendar fa-fw w3-margin-right" />
                  Aug 2021 -{" "}
                  <span className="w3-tag w3-blue w3-round">Current</span>
                </h6>
                <p>
                  Application Designing & Development | R&D | Full Stack Web
                  Development(MEAN Stack) & Cloud Engineering(AWS)
                </p>
                <hr />
              </div>
              <div className="w3-container">
                <h5 className="w3-opacity">
                  <b>
                    Co-Founder | Teckytrick Consultancy Services Private Limited
                  </b>
                </h5>
                <h6 className="w3-text-blue">
                  <i className="fa fa-calendar fa-fw w3-margin-right" />
                  Mar 2020 - July 2021
                </h6>
                <p>
                  Co-Founded & served as a Technical Head of Teckytrick
                  Consultancy Services Private Limited & worked in a client
                  facing role.
                </p>
                <hr />
              </div>
              <div className="w3-container">
                <h5 className="w3-opacity">
                  <b>Assistant Systems Engineer | Tata Consultancy Services</b>
                </h5>
                <h6 className="w3-text-blue">
                  <i className="fa fa-calendar fa-fw w3-margin-right" />
                  Dec 2018 - Mar 2020
                </h6>
                <p>Core Java Developer</p>
                <br />
              </div>
            </div>
            <div className="w3-container w3-card w3-white">
              <h2 className="w3-text-grey w3-padding-16">
                <i className="fa fa-certificate fa-fw w3-margin-right w3-xxlarge w3-text-blue" />
                Education
              </h2>
              <div className="w3-container">
                <h5 className="w3-opacity">
                  <b>Government College of Engineering Aurangabad</b>
                </h5>
                <h6 className="w3-text-blue">
                  <i className="fa fa-calendar fa-fw w3-margin-right" />
                  2014 - 2018
                </h6>
                <p>BE Information Technology, 7.297 CGPA ~ 72.97%</p>
                <hr />
              </div>
              <div className="w3-container">
                <h5 className="w3-opacity">
                  <b>
                    Maharashtra State Board of Secondary and Higher Secondary
                    Education
                  </b>
                </h5>
                <h6 className="w3-text-blue">
                  <i className="fa fa-calendar fa-fw w3-margin-right" />
                  2012 - 2014
                </h6>
                <p>HSC, 75.54%</p>
                <hr />
              </div>
              <div className="w3-container">
                <h5 className="w3-opacity">
                  <b>
                    Maharashtra State Board of Secondary and Higher Secondary
                    Education
                  </b>
                </h5>
                <h6 className="w3-text-blue">
                  <i className="fa fa-calendar fa-fw w3-margin-right" />
                  2011 - 2012
                </h6>
                <p>SSC, 85.82%</p>
                <br />
              </div>
            </div>
            {/* End Right Column */}
          </div>
          {/* End Grid */}
        </div>
        {/* End Page Container */}
      </div>
      <footer className="w3-container w3-blue w3-center w3-margin-top">
        <p>Find me on social media.</p>
        <a href="https://www.instagram.com/jaysenwankhade" target="_blank">
          <i className="fa fa-instagram w3-hover-opacity" />
        </a>{" "} {" "}
        <a href="https://www.linkedin.com/in/jaysen-wankhade" target="_blank">
          <i className="fa fa-linkedin w3-hover-opacity" />
        </a>
        <p>
          Powered by{" "}
          <a href="https://www.w3schools.com/w3css/default.asp" target="_blank">
            w3.css
          </a>
        </p>
      </footer>
    </>
  );
}

export default App;
